import BasicService from "./BasicService";

class InvDetailService extends BasicService {
  constructor() {
    super();
  }

  getInvDetailList() {
    let url = `/invDetail`;
    return this.axios.get(url).then(response => response.data);
  }
  getInvoiceDtlById(invoiceId) {
    let url = `/invDetail/detail`;
    return this.axios.get(url,{
      params:{
        invoiceId
      }
    }).then(response => response.data);
  }
  addInvDetail(invDetail) {
    let url = `/invDetail`;
    return this.axios.post(url,invDetail).then(response => response.data);
  }
  updateInvDetail(invDetail) {
    let url = `/invDetail/${invDetail.invDetailId}`;
    return this.axios.put(url,invDetail).then(response => response.data);
  }
  deleteInvDetail(invDetail) {
    let url = `invDetail/${invDetail.invDetailId}`;
    return this.axios.delete(url).then(response => response.data);
  }
}
const service = new InvDetailService();
export default service;
