<template>
    <v-row no-gutters>
            <v-col cols="12">
        <v-toolbar dark color="primary pl-2 pr-2" dense>
            <v-toolbar-title>Invoice Detail - {{invDtl.type }}  ({{invDtl.invoiceCode }})</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('closeDialog')">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="12" class="pl-1 pt-1 pb-1">
          <v-simple-table
            :fixed-header="true"
            class="elevation-1 align-center"
            height="91vh"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center white--text primary">No.</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Note</th>
                   <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Remark</th>
                   <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Qty</th>
                   <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Price</th>                  
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in invDtlList"
                  :key="index"
                  @click="selectedOne = item"
                  :style="{
                    backgroundColor:
                      item.invDetailId == selectedOne.invDetailId
                        ? '#def3ff'
                        : 'transparent',
                  }"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{ item.note.note }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{ item.remark }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{ item.qty }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{ item.price }}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{ item.amount }}</td>
                </tr>
                <v-divider />
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        </v-row>
    </template>
    <script>
    import invDtlService from "../service/InvDetailService";
    export default {
      data: () => ({
        selectedOne: {},  
        invDtlList:[],
      }),
       props: {invDtl:Object},
      mounted: function() {
        this.invDtlListMethod();
      },
      methods: {
        invDtlListMethod: function () {
        invDtlService
        .getInvoiceDtlById(this.invDtl.invoiceId)
        .then((response) => {
          this.invDtlList.splice(0);
          this.invDtlList.push(...response);
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });        
    },
      },
      watch: {
        invDtl:function(){
        this.invDtlListMethod();
        },
      },
      components: {}
    };
    </script>
    <style scoped>
    .v-data-table td,
    .v-data-table th {
      padding: 0 5px !important;
    }
    .login-full {
      height: 91vh;
    }
    </style>